<template>
  <b-container class="bg-light d-flex flex-column justify-content-center" fluid>
    <b-container fluid>
      <b-form @submit.prevent="pay">
        <b-row>
          <b-col cols="12" md="6" offset-md="3">
            <b-row class="mb-4">
              <b-col class="text-center" cols="12">
                <img class="img-responsive w-75 w-lg-50" src="../../assets/logo.svg" alt="Better Living Outdoors">
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12">
                <b-card title="Billing Address">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Address Line 1" label-for="address_line_1">
                        <b-form-input :disabled="paying" id="address_line_1" required type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Address Line 2 (Optional)" label-for="address_line_2">
                        <b-form-input :disabled="paying" id="address_line_2" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="City" label-for="city">
                        <b-form-input :disabled="paying" id="city" required type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="County" label-for="county">
                        <b-form-input :disabled="paying" id="county" required type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Postcode" label-for="postcode">
                        <b-form-input :disabled="paying" id="postcode" required type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12">
                <b-card title="Card Details">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Card Holder Name" label-for="card_holder_name">
                        <b-form-input :disabled="paying" id="card_holder_name" required type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <div :disabled="paying" id="card-elements" class="form-control"></div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-button block class="font-weight-bold" :disabled="paying" type="submit" variant="primary"><span v-if="paying"><b-spinner class="mr-2" small variant="light" />Sending Payment...</span><span v-if="!paying">Pay {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(this.amount_pence / 100) }} to Better Living Outdoors</span></b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col cols="12">
                <p class="mb-2 px-md-3 text-center "><small>All payments on this page are powered by <a href="https://stripe.com">Stripe</a> and are PCI and SCA compliant. Better Living Outdoors does not store your card information.</small></p>
                <p class="mb-2 text-center"><small><a href="https://betterlivingoutdoors.com/privacy-policy">Privacy Policy</a> &dash; <a href="https://betterlivingoutdoors.com/terms-conditions">Terms &amp; Conditions</a> &dash; <a href="https://betterlivingoutdoors.com/contact-us">Contact Us</a></small></p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </b-container>
</template>

<script>
/* global Stripe */
export default {
  data () {
    return {
      stripePublicToken: process.env.VUE_APP_STRIPE_PK,
      amount_pence: 197,
      elements: null,
      paying: false,
      stripe: null,
      card: null
    }
  },
  mounted () {
    this.loadStripe('js.stripe.com/v3/', () => {
      this.configureStripe()
    })
  },
  methods: {
    configureStripe () {
      this.stripe = Stripe(this.stripePublicToken)
      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', { hidePostalCode: true })
      this.card.mount('#card-elements')
    },
    loadStripe (url, callback) {
      const documentTag = document
      const tag = 'script'
      const object = documentTag.createElement(tag)
      const scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.setAttribute('defer', 'defer')
      object.setAttribute('src', '//' + url)
      if (callback) { object.addEventListener('load', function (e) { callback(null, e) }, false) }
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },
    pay (e) {
      this.paying = true
      this.card.disabled = true
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>
  #card-elements {
    padding: 0.6rem 0.75rem;
  }

  .container-fluid {
    min-height: 100vh;
  }
</style>
